import { getLanguageFromPath } from '~/helpers/language'

/**
 * Middleware that makes sure the signup flow is respected.
 */
export default defineNuxtRouteMiddleware((to) => {
  // Redirect to the signup if someone tries to open the route directly via URL.
  if (process.server) {
    return navigateTo({
      name: 'signup___' + getLanguageFromPath(to.path),
    })
  }
})
